<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 px-5 d-flex align-center border-bottom drawer-header"
        >
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Properties </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            tile
            class="me-2 custom-grey-border custom-bold-button"
            v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="red--text fw-500">Field lavel</label>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    class="text--blue fw-500 cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                    >Rich Text</label
                  >
                </template>
                <v-card tile>
                  <v-card-title class="text-h5 cyan white--text d-flex">
                    <span>Edit Field label</span>
                    <v-spacer></v-spacer>
                    <v-icon
                      class="white--text cursor-pointer"
                      @click="dialog = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>
                  <div class="dialog-body p-5">
                    <editor
                      :editor="editor"
                      v-model="editorData"
                      :config="editorConfig"
                    />
                  </div>
                  <v-card-actions class="border-top">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" tile @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="cyan"
                      class="white--text"
                      depressed
                      @click="dialog = false"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.label"
            ></v-text-field>
            <v-checkbox
              hide-details
              v-model="properties.hidelabel"
              label="Hide Field Label"
              class="mt-1"
            ></v-checkbox>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Terms</label>
            </div>
            <v-dialog v-model="termsDialog" persistent max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="text-center py-5 border-gray text-muted"
                  v-bind="attrs"
                  v-on="on"
                >
                  Click here to edit your terms
                </div>
              </template>
              <v-card tile>
                <v-card-title class="text-h5 cyan white--text d-flex">
                  <span>Edit Terms</span>
                  <v-spacer></v-spacer>
                  <v-icon
                    class="white--text cursor-pointer"
                    @click="termsDialog = false"
                    >mdi-close</v-icon
                  >
                </v-card-title>
                <div class="dialog-body p-5">
                  <editor
                    :editor="editor"
                    v-model="termsData"
                    :config="editorConfig"
                  />
                </div>
                <v-card-actions class="border-top">
                  <v-spacer></v-spacer>
                  <v-btn class="mr-2" tile @click="termsDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-4"
                    class="white--text"
                    tile
                    depressed
                    @click="termsDialog = false"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-checkbox
              hide-details
              v-model="properties.terms"
              label="Show Terms"
              class="mt-1"
            ></v-checkbox>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500 red--text">Declaration</label>
            </div>
            <v-dialog v-model="declarationDialog" persistent max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="text-center py-5 border-gray text-muted"
                  v-bind="attrs"
                  v-on="on"
                >
                  Click here to edit your Declaration
                </div>
              </template>
              <v-card tile>
                <v-card-title class="text-h5 cyan white--text d-flex">
                  <span>Edit Declaration</span>
                  <v-spacer></v-spacer>
                  <v-icon
                    class="white--text cursor-pointer"
                    @click="declarationDialog = false"
                    >mdi-close</v-icon
                  >
                </v-card-title>
                <div class="dialog-body p-5">
                  <editor
                    :editor="editor"
                    v-model="declarationData"
                    :config="editorConfig"
                  />
                </div>
                <v-card-actions class="border-top">
                  <v-spacer></v-spacer>
                  <v-btn class="mr-2" tile @click="declarationDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-4"
                    class="white--text"
                    tile
                    depressed
                    @click="declarationDialog = false"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500 red--text">Status of consents</label>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.consents"
            ></v-text-field>
            <p class="text-muted">
              It will be used in emails document merge and integrations.
            </p>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Validation</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.mandatory"
              label="Mandatory"
              class="mt-1"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "TermsAndConditions",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      termsDialog: false,
      declarationDialog: false,
      //   editor: ClassicEditor,
      editor: TinyMCE,
      editorData: "<p>Terms and Conditions</p>",
      termsData: "<p>Terms and Conditions</p>",
      declarationData: "<p>Terms and Conditions</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      properties: {
        label: "Terms and Conditions",
        dialog: false,
        hidelabel: false,
        consents: "Agreed",
        instructions: null,
        visibility: null,
      },
    };
  },
  components: {
    editor: TinyMCE,
  },
};
</script>
