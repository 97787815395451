<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 px-5 d-flex align-center border-bottom drawer-header"
        >
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Properties </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            tile
            class="light darken-4 me-2"
            v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="red--text fw-500">Field lavel</label>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    class="text--blue fw-500 cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                    >Rich Text</label
                  >
                </template>
                <v-card tile>
                  <v-card-title class="text-h5 cyan white--text d-flex">
                    <span>Edit Field label</span>
                    <v-spacer></v-spacer>
                    <v-icon
                      class="white--text cursor-pointer"
                      @click="dialog = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>
                  <div class="dialog-body p-5">
                    <editor
                      :editor="editor"
                      v-model="editorData"
                      :config="editorConfig"
                    ></editor>
                  </div>
                  <v-card-actions class="border-top">
                    <v-spacer></v-spacer>
                    <v-btn class="text" tile @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="cyan"
                      class="white--text"
                      depressed
                      @click="dialog = false"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.label"
            ></v-text-field>
            <v-checkbox
              hide-details
              v-model="properties.hidelabel"
              label="Hide Field Label"
              class="mt-1"
            ></v-checkbox>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Instructions</label>
            </div>
            <v-textarea
              v-model="properties.instructions"
              auto-grow
              dense
              filled
              solo
              flat
              color="cyan"
              row-height="20"
            ></v-textarea>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Field Size</label>
            </div>
            <v-radio-group
              v-model="properties.fieldSize"
              hide-details
              row
              class="mt-0 radio-style"
            >
              <v-radio label="Small" value="small"></v-radio>
              <v-radio label="Medium" value="medium"></v-radio>
              <v-radio label="Large" value="large"></v-radio>
            </v-radio-group>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Placeholder Text</label>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.placeholder"
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Initial Value</label>
            </div>
            <v-textarea
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              rows="3"
              v-model="properties.initialVal"
            ></v-textarea>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Range</label>
            </div>
            <div class="two-column d-flex">
              <div class="w-25 pr-3">
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="properties.max_character"
                ></v-text-field>
                <div class="d-flex pb-1">
                  <label class="text-muted">Minimum</label>
                </div>
              </div>
              <div class="w-25 pl-3">
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="properties.min_character"
                ></v-text-field>
                <div class="d-flex pb-1">
                  <label class="text-muted">Maximum</label>
                </div>
              </div>
              <div class="w-50 pl-3">
                <v-autocomplete
                  :items="rangeFormat"
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="properties.rangeFormat"
                ></v-autocomplete>
                <div class="d-flex pb-1">
                  <label class="text-muted">Format</label>
                </div>
              </div>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.characterCount"
              label="Show Character Count"
              class="mt-1"
            ></v-checkbox>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Validation</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.mandatory"
              label="Mandatory"
              class="mt-1"
            ></v-checkbox>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Visibility</label>
            </div>
            <v-radio-group
              v-model="properties.visibility"
              hide-details
              class="mt-0"
            >
              <v-radio label="Hide Field" value="hide"></v-radio>
              <v-radio label="Disable Field" value="disable"></v-radio>
            </v-radio-group>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500"
                >Privacy
                <v-icon class="mdi-18px">mdi-information-outline</v-icon>
              </label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.personal"
              label="Mark as Personal"
              class="mt-1"
            ></v-checkbox>
            <v-checkbox
              hide-details
              v-model="properties.encrypt"
              label="Encrypt"
              class="mt-1"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "MultiLine",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      editor: TinyMCE,
      editorData: "<p>Multi Line</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      properties: {
        label: "Multi Line",
        dialog: false,
        hidelabel: false,
        instructions: null,
        placeholder: null,
        fieldSize: "small",
        initialVal: null,
        min_character: null,
        max_character: null,
        rangeFormat: "Character",
        visibility: null,
        characterCount: false,
      },
      rangeFormat: ["Character", "Word"],
    };
  },
  components: {
    editor: TinyMCE,
  },
};
</script>
