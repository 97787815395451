<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <ul>
        <li
          v-for="(item, index) in checklist"
          :key="index"
          class="d-flex align-center"
        >
          <!-- Single Check -->
          <template v-if="item.selectedChecklist === 'single'">
            <label
              class="fw-500 mb-0"
              style="max-width: 500px; min-width: 70px"
            >
              {{ item.label }}
            </label>
            <v-checkbox class="ms-2" v-model="item.yes"></v-checkbox>
            <v-checkbox class="mx-2" v-model="item.no"></v-checkbox>
          </template>

          <!-- Both Check -->
          <template v-else-if="item.selectedChecklist === 'both'">
            <label
              class="fw-500 mb-0"
              style="max-width: 500px; min-width: 70px"
            >
              {{ item.label }}
            </label>
            <v-checkbox class="ms-2" v-model="item.yes"></v-checkbox>
            <v-checkbox class="mx-2" v-model="item.no"></v-checkbox>
          </template>

          <!-- Number Input -->
          <template v-else-if="item.selectedChecklist === 'number'">
            <label
              class="fw-500 mb-0"
              style="max-width: 500px; min-width: 70px"
            >
              {{ item.label }}
            </label>
            <div style="min-width: 100px; width: 100px">
              <v-text-field
                depressed
                hide-details
                outlined
                dense
                filled
                solo
                flat
                v-model.number="item.number"
                type="number"
              ></v-text-field>
            </div>
          </template>
        </li>
      </ul>

      <p class="text-muted mt-2 mb-0" v-if="properties.value != null">
        {{ properties.intructions }}
      </p>
      <div class="mt-2" v-if="properties.value">
        <DocumnetList v-model="properties.attachments"> </DocumnetList>
      </div>
    </div>
    <CheckList :drawer="dialog" v-on:close="dialog = false"></CheckList>
  </div>
</template>
<script>
import CheckList from "@/view/pages/formBuilder/components/CheckList";
import DocumnetList from "@/view/pages/DocumnetList";
export default {
  name: "ChecklistInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Checklist",
        value: null,
        intructions: "Intructions",
      },
      checklist: [
        {
          label: "Single",
          yes: true,
          no: false,
          selectedChecklist: "single",
        },
        { label: "both", yes: true, no: true, selectedChecklist: "both" },
        { label: "Number", number: null, selectedChecklist: "" },
      ],
    };
  },
  components: {
    CheckList,
    DocumnetList,
  },
};
</script>
