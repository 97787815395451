<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <v-radio-group v-model="properties.value" class="mt-0" hide-details>
        <v-radio hide-details value="value1" label="Radio 1"></v-radio>
        <v-radio hide-details value="value2" label="Radio 2"></v-radio>
        <v-radio hide-details value="value3" label="Radio 3"></v-radio>
      </v-radio-group>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <Radio :drawer="dialog" v-on:close="dialog = false"></Radio>
  </div>
</template>
<script>
import Radio from "@/view/pages/formBuilder/components/Radio";
export default {
  name: "RadioInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Radio",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    Radio,
  },
};
</script>
