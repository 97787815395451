<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 px-5 d-flex align-center border-bottom drawer-header"
        >
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Properties </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn depressed tile class="me-2" v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="red--text fw-500">Field lavel</label>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    class="text--blue fw-500 cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                    >Rich Text</label
                  >
                </template>
                <v-card tile>
                  <v-card-title class="text-h5 cyan white--text d-flex">
                    <span>Edit Field label</span>
                    <v-spacer></v-spacer>
                    <v-icon
                      class="white--text cursor-pointer"
                      @click="dialog = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>
                  <div class="dialog-body p-5">
                    <editor
                      :editor="editor"
                      v-model="editorData"
                      :config="editorConfig"
                    ></editor>
                  </div>
                  <v-card-actions class="border-top">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" tile @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="cyan"
                      class="white--text"
                      depressed
                      @click="dialog = false"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.label"
            ></v-text-field>
            <v-checkbox
              hide-details
              v-model="properties.hidelabel"
              label="Hide Field Label"
              class="mt-1"
            ></v-checkbox>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Instructions</label>
            </div>
            <v-textarea
              v-model="properties.instructions"
              auto-grow
              dense
              filled
              solo
              flat
              color="cyan"
              row-height="20"
            ></v-textarea>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Placeholder Text</label>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.placeholder"
            ></v-text-field>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Initial Value</label>
            </div>
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="properties.initialVal"
                  append-icon="mdi-calendar"
                  readonly
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="properties.initialVal"
                @input="datePicker = false"
              ></v-date-picker>
            </v-menu>
            <v-checkbox
              hide-details
              v-model="properties.autofillDate"
              label="Autofill Date of Response"
              class="mt-1"
            ></v-checkbox>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Week Start On</label>
            </div>
            <v-autocomplete
              :items="weekDays"
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.weekDays"
            ></v-autocomplete>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Allowed Days</label>
            </div>
            <v-autocomplete
              :items="allowedDays"
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.allowedDays"
            ></v-autocomplete>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Allowed Date</label>
            </div>
            <v-autocomplete
              :items="allowedDates"
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.allowedDates"
            ></v-autocomplete>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Validation</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.mandatory"
              label="Mandatory"
              class="mt-1"
            ></v-checkbox>
            <v-checkbox
              hide-details
              v-model="properties.duplicate"
              label="No Duplicates"
              class="mt-1"
            ></v-checkbox>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Column</label>
            </div>
            <v-select
              dense
              filled
              solo
              flat
              :items="[
                {
                  text: 'Full width (width:100%)',
                  value: '12',
                },
                {
                  text: 'Two Column (width:50%)',
                  value: '6',
                },
                {
                  text: 'Three Column (width:33%)',
                  value: '4',
                },
              ]"
              placeholder="Column"
              hide-details
              color="cyan"
              v-model="properties.column"
            />
            <div class="field-preview mt-5">
              <h3>Preview</h3>
              <v-row>
                <v-col :cols="properties.column">
                  <v-text-field
                    depressed
                    hide-details
                    outlined
                    dense
                    filled
                    solo
                    flat
                    v-model="properties.text"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Visibility</label>
            </div>
            <v-radio-group
              v-model="properties.visibility"
              hide-details
              class="mt-0"
            >
              <v-radio label="Hide Field" value="hide"></v-radio>
              <v-radio label="Disable Field" value="disable"></v-radio>
            </v-radio-group>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500"
                >Privacy
                <v-icon class="mdi-18px">mdi-information-outline</v-icon>
              </label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.personal"
              label="Mark as Personal"
              class="mt-1"
            ></v-checkbox>
            <v-checkbox
              hide-details
              v-model="properties.encrypt"
              label="Encrypt"
              class="mt-1"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "Date",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      datePicker: false,
      editor: TinyMCE,
      editorData: "<p>Date</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      properties: {
        label: "Date",
        dialog: false,
        hidelabel: false,
        instructions: null,
        placeholder: null,
        fieldSize: "small",
        initialVal: null,
        max_range: null,
        min_range: null,
        column: 12,
        unit: null,
        range_format: null,
        visibility: null,
      },
      field_format: ["International", "###########"],
      weekDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
      ],
      allowedDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      allowedDates: [
        "All Dates",
        "Past Dates",
        "Future Dates",
        "Date of Response",
        "Start From",
      ],
    };
  },
  components: {
    editor: TinyMCE,
  },
};
</script>
