<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <v-menu
        v-model="datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            disabled
            v-model="properties.value"
            append-icon="mdi-calendar"
            readonly
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="properties.value"
          @input="datePicker = false"
        ></v-date-picker>
      </v-menu>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <Date :drawer="dialog" v-on:close="dialog = false"></Date>
  </div>
</template>
<script>
import Date from "@/view/pages/formBuilder/components/Date";
export default {
  name: "DateInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      datePicker: false,
      properties: {
        label: "Date",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    Date,
  },
};
</script>
