<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 px-5 d-flex align-center border-bottom drawer-header"
        >
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Properties </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn depressed tile class="me-2" v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="red--text fw-500">Field lavel</label>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    class="text--blue fw-500 cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                    >Rich Text</label
                  >
                </template>
                <v-card tile>
                  <v-card-title class="text-h5 cyan white--text d-flex">
                    <span>Edit Field label</span>
                    <v-spacer></v-spacer>
                    <v-icon
                      class="white--text cursor-pointer"
                      @click="dialog = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>
                  <div class="dialog-body p-5">
                    <editor
                      :editor="editor"
                      v-model="editorData"
                      :config="editorConfig"
                    ></editor>
                  </div>
                  <v-card-actions class="border-top">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" tile @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="cyan"
                      class="white--text"
                      depressed
                      @click="dialog = false"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.label"
            ></v-text-field>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Instructions</label>
            </div>
            <v-textarea
              v-model="properties.instructions"
              auto-grow
              dense
              filled
              solo
              flat
              color="cyan"
              row-height="20"
            ></v-textarea>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Show data in</label>
            </div>
            <v-autocomplete
              :items="showData"
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.showData"
            ></v-autocomplete>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-3">
              <label class="fw-500">Checklist</label>
            </div>
            <v-row class="my-0">
              <v-col md="10" class="py-0"
                ><label class="fw-500">Title</label></v-col
              >
              <v-col md="2" class="py-0"></v-col>
            </v-row>
            <v-row
              class="d-flex align-center my-0"
              v-for="(item, index) in checklist"
              :key="index"
            >
              <v-col md="10">
                <!-- Select Field -->
                <div class="d-flex align-center">
                  <v-text-field
                    class="me-2"
                    depressed
                    hide-details
                    outlined
                    dense
                    filled
                    solo
                    flat
                    placeholder="Item"
                    v-model="item.value"
                  ></v-text-field>
                  <!-- {{ item.selectedChecklist }} -->
                  <!-- Settings Menu -->
                  <v-menu left dense>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-radio-group
                            v-model="item.selectedChecklist"
                            column
                          >
                            <v-radio
                              label="Single Check"
                              value="single"
                            ></v-radio>
                            <v-radio label="Both Check" value="both"></v-radio>
                            <v-radio
                              label="Number Input"
                              value="number"
                            ></v-radio>
                          </v-radio-group>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>

              <v-col md="2" class="d-flex">
                <!-- Add New Item Button -->
                <v-icon
                  class="cursor-pointer mx-2 success--text"
                  @click="addNew(index)"
                >
                  mdi-plus-circle
                </v-icon>

                <!-- Remove Item Button -->
                <v-icon
                  class="cursor-pointer mx-2 red--text"
                  @click="remove(index)"
                >
                  mdi-minus-circle
                </v-icon>
              </v-col>
            </v-row>
          </div>
          <div class="form-grp px-5 mb-4" v-if="false">
            <div class="d-flex pb-3">
              <label class="fw-500">Choise</label>
              <v-spacer></v-spacer>
              <v-menu left v-if="false">
                <template v-slot:activator="{ on, attrs }">
                  <a class="primary--text" v-on="on" v-bind="attrs"
                    >Advance
                    <v-icon>mdi-menu-down</v-icon>
                  </a>
                </template>
                <v-list>
                  <!-- Import Dialog -->
                  <v-dialog v-model="importDialog" persistent max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-title>
                          <v-icon class="mdi-18px primary--text"
                            >mdi-import</v-icon
                          >
                          Import</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                    <v-card tile>
                      <v-card-title class="text-h5 cyan white--text d-flex">
                        <span>Import</span>
                        <v-spacer></v-spacer>
                        <v-icon
                          class="white--text cursor-pointer"
                          @click="importDialog = false"
                          >mdi-close</v-icon
                        >
                      </v-card-title>
                      <div class="dialog-body p-5">
                        <div class="d-flex">
                          <div class="w-40 border-gray">
                            <ul class="import-list">
                              <li>Gender</li>
                              <li>Age</li>
                              <li>Employment</li>
                              <li>Income</li>
                            </ul>
                          </div>
                          <div class="w-60 p-3 border-gray">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Corporis quisquam ducimus id quasi illum magni
                            labore inventore numquam blanditiis nostrum quo
                            eaque perspiciatis, libero est? Atque nam temporibus
                            minus accusantium.
                          </div>
                        </div>
                      </div>
                      <v-card-actions class="border-top">
                        <v-spacer></v-spacer>
                        <v-btn class="mr-2" tile @click="importDialog = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-4"
                          class="white--text"
                          tile
                          depressed
                          @click="importDialog = false"
                        >
                          Add Choise
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- Copy Dialog -->
                  <v-dialog v-model="copyDialog" persistent max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-title>
                          <v-icon class="mdi-18px primary--text"
                            >mdi-content-copy</v-icon
                          >
                          Copy</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                    <v-card tile>
                      <v-card-title class="text-h5 cyan white--text d-flex">
                        <span>Copy Values</span>
                        <v-spacer></v-spacer>
                        <v-icon
                          class="white--text cursor-pointer"
                          @click="copyDialog = false"
                          >mdi-close</v-icon
                        >
                      </v-card-title>
                      <div class="dialog-body p-5">
                        <div
                          class="p-3 border-gray"
                          style="height: 150px; overflow-y: scroll"
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit.<br />
                          Corporis quisquam ducimus id quasi illum magni labore
                          inventore numquam blanditiis nostrum quo eaque
                          perspiciatis,<br />
                          libero est? Atque nam temporibus minus accusantium.
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <!-- Assign Value Dialog -->
                  <v-dialog
                    v-model="assignValueDialog"
                    persistent
                    max-width="600"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-title>
                          <v-icon class="mdi-18px primary--text"
                            >mdi-arrow-all</v-icon
                          >
                          Assign Values</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                    <v-card tile>
                      <v-card-title class="text-h5 cyan white--text d-flex">
                        <span>Assign values</span>
                        <v-spacer></v-spacer>
                        <v-icon
                          class="white--text cursor-pointer"
                          @click="assignValueDialog = false"
                          >mdi-close</v-icon
                        >
                      </v-card-title>
                      <div class="dialog-body p-5">
                        <div class="form-grp mb-4">
                          <div class="two-column d-flex">
                            <div class="w-50 pr-3">
                              <div class="d-flex pb-1">
                                <label class="">Value Type</label>
                              </div>
                              <v-autocomplete
                                :items="valueTypeList"
                                depressed
                                hide-details
                                outlined
                                dense
                                filled
                                solo
                                flat
                                v-model="valueType"
                              ></v-autocomplete>
                            </div>
                            <div class="w-50 pl-3">
                              <div class="d-flex pb-1">
                                <label class="">Allowed Decimal places</label>
                              </div>
                              <v-autocomplete
                                :items="decimallist"
                                depressed
                                hide-details
                                outlined
                                dense
                                filled
                                solo
                                flat
                                v-model="decimal_place"
                              ></v-autocomplete>
                            </div>
                          </div>
                        </div>
                        <div class="form-grp mb-4">
                          <div class="two-column d-flex py-1 border">
                            <div class="w-50 px-2">
                              <label class="fw-500">Choise</label>
                            </div>
                            <div
                              class="px-2"
                              :class="
                                valueType != 'Numeric and Text'
                                  ? 'w-50'
                                  : 'w-25'
                              "
                              v-if="
                                valueType == 'Numeric' ||
                                valueType == 'Numeric and Text'
                              "
                            >
                              <label class="fw-500">Numeric</label>
                            </div>
                            <div
                              class="px-2"
                              :class="
                                valueType != 'Numeric and Text'
                                  ? 'w-50'
                                  : 'w-25'
                              "
                              v-if="
                                valueType == 'Text' ||
                                valueType == 'Numeric and Text'
                              "
                            >
                              <label class="fw-500">Text</label>
                            </div>
                          </div>
                          <div
                            class="two-column d-flex py-1 border-bottom"
                            v-for="(item, index) in choiseData"
                            :key="index"
                          >
                            <div class="w-50 px-2 my-auto">
                              <label class="">{{ item.label }}</label>
                            </div>
                            <div
                              class="px-2"
                              :class="
                                valueType != 'Numeric and Text'
                                  ? 'w-50'
                                  : 'w-25'
                              "
                              v-if="
                                valueType == 'Numeric' ||
                                valueType == 'Numeric and Text'
                              "
                            >
                              <v-text-field
                                depressed
                                hide-details
                                outlined
                                dense
                                filled
                                solo
                                flat
                                v-model="value1"
                              ></v-text-field>
                            </div>
                            <div
                              class="px-2"
                              :class="
                                valueType != 'Numeric and Text'
                                  ? 'w-50'
                                  : 'w-25'
                              "
                              v-if="
                                valueType == 'Text' ||
                                valueType == 'Numeric and Text'
                              "
                            >
                              <v-text-field
                                depressed
                                hide-details
                                outlined
                                dense
                                filled
                                solo
                                flat
                                v-model="value2"
                              ></v-text-field>
                            </div>
                          </div>
                        </div>
                      </div>
                      <v-card-actions class="border-top">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-2"
                          tile
                          @click="assignValueDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-4"
                          class="white--text"
                          tile
                          depressed
                          @click="assignValueDialog = false"
                        >
                          Assign Values
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list>
              </v-menu>
            </div>
            <ul>
              <li
                class="d-flex mb-5"
                v-for="(item, index) in choiseData"
                :key="index"
              >
                <v-checkbox
                  hide-details
                  v-model="item.check_status"
                  class="mt-0"
                ></v-checkbox>
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  :placeholder="item.label"
                  v-model="value"
                ></v-text-field>
                <v-icon
                  class="cursor-pointer mx-1 success--text"
                  v-on:click="addNew(index)"
                  >mdi-plus-circle</v-icon
                >
                <v-icon
                  class="cursor-pointer mx-1 red--text"
                  v-on:click="remove(index)"
                  >mdi-minus-circle</v-icon
                >
              </li>
            </ul>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Choise Limit</label>
            </div>
            <div class="two-column d-flex">
              <div class="w-50 pr-3">
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="properties.max_character"
                ></v-text-field>
                <div class="d-flex pt-1">
                  <label class="text-muted">Minimum</label>
                </div>
              </div>
              <div class="w-50 pl-3">
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="properties.min_character"
                ></v-text-field>
                <div class="d-flex pt-1">
                  <label class="text-muted">Maximum</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Sort Choise</label>
            </div>
            <v-autocomplete
              :items="inputTypeValue"
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.sort_choise"
            ></v-autocomplete>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">When Check</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.isAttachments"
              label="Add Check"
              class="mt-0"
            ></v-checkbox>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500"> Validation Attachments</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.isMandatoryAttach"
              label="Mandatory Attachments"
              class="mt-0"
            ></v-checkbox>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Validation</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.validation_mandatory"
              class="mt-0"
              label="Mandatory"
            ></v-checkbox>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Visibility</label>
            </div>
            <v-radio-group
              v-model="properties.visibility"
              hide-details
              class="mt-0"
            >
              <v-radio label="Hide Field" value="hide"></v-radio>
              <v-radio label="Disable Field" value="disable"></v-radio>
            </v-radio-group>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500"
                >Privacy
                <v-icon class="mdi-18px">mdi-information-outline</v-icon>
              </label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.personal"
              label="Mark as Personal"
              class="mt-1"
            ></v-checkbox>
            <v-checkbox
              hide-details
              v-model="properties.encrypt"
              label="Encrypt"
              class="mt-1"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "CheckBox",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      importDialog: false,
      copyDialog: false,
      assignValueDialog: false,
      editor: TinyMCE,
      editorData: "<p>Check List</p>",
      valueType: "Numeric",
      decimal_place: 0,
      value1: null,
      value2: null,
      editorConfig: {
        // The configuration of the editor.
      },
      properties: {
        label: "Check List",
        dialog: false,
        hidelabel: false,
        instructions: null,
        placeholder: null,
        fieldSize: "small",
        inputType: "Any Character",
        visibility: null,
        isAttachments: false,
        isMandatoryAttach: false,
        validation_mandatory: false,
        sort_choise: null,
      },
      value: null,

      checklist: [
        { label: "Item 1", yes: true, no: false, selectedChecklist: "single" },
      ],
      choiseData: [
        { label: "Fist choise", check_status: false, value: 1 },
        { label: "Second choise", check_status: false, value: 2 },
        { label: "Third choise", check_status: false, value: 3 },
      ],
      showData: ["1 Column", "2 Column", "3 Column", "Side by Side"],
      inputTypeValue: ["None", "Ascending", "Descending"],
      valueTypeList: ["Numeric", "Text", "Numeric and Text"],
      decimallist: [0, 1, 2],
    };
  },
  methods: {
    toggleCheckbox(item, field) {
      if (field === "yes") {
        item.no = !item.yes ? item.no : false;
      } else {
        item.yes = !item.no ? item.yes : false;
      }
    },
    addNew(index) {
      // let len = this.checklist.length;
      this.checklist.splice(index + 1, 0, {
        value: "",
        selectedChecklist: "single",
        yes: false,
        no: false,
        number: null,
      });
    },
    remove(index) {
      this.checklist.splice(index, 1);
    },
  },
  components: {
    editor: TinyMCE,
  },
};
</script>
<style scoped></style>
