<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <div class="two-column d-flex">
        <div class="w-25 pr-3">
          <v-autocomplete
            items:hh_List
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.hh_value"
          ></v-autocomplete>
          <div class="d-flex pt-1">
            <label class="text-muted">{{ properties.hh_label }}</label>
          </div>
        </div>
        <div class="w-25 pl-3">
          <v-autocomplete
            items:mm_List
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.mm_value"
          ></v-autocomplete>
          <div class="d-flex pt-1">
            <label class="text-muted">{{ properties.mm_label }}</label>
          </div>
        </div>
        <div class="w-25 pl-3">
          <v-autocomplete
            items:type_List
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.meridian_value"
          ></v-autocomplete>
        </div>
      </div>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <Time :drawer="dialog" v-on:close="dialog = false"></Time>
  </div>
</template>
<script>
import Time from "@/view/pages/formBuilder/components/Time";
export default {
  name: "TimeInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Time",
        hh_label: "HH",
        mm_label: "MM",
        hh_value: null,
        mm_value: null,
        meridian_value: null,
        intructions: "lorem",
      },
      hh_List: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      mm_List: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      type_List: ["AM", "PM"],
    };
  },
  components: {
    Time,
  },
};
</script>
