<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 px-5 d-flex align-center border-bottom drawer-header"
        >
          <div class="font-level-3-bold">Unique Id</div>
          <v-spacer></v-spacer>
          <v-btn depressed tile class="me-2" v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="red--text fw-500">Start From</label>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.startFrom"
            ></v-text-field>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Prefix</label>
              <v-spacer></v-spacer>
              <v-icon
                class="mdi-18px border-gray p-1 cursor-pointer dark--text"
                v-on:click="prefix_autocomplete = !prefix_autocomplete"
                >mdi-text-box-plus-outline</v-icon
              >
            </div>
            <div class="mb-3" v-if="prefix_autocomplete">
              <v-autocomplete
                :items="toolsList"
                depressed
                hide-details
                outlined
                dense
                filled
                solo
                flat
                v-model="properties.prefix_id"
              ></v-autocomplete>
            </div>
            <v-textarea
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              rows="4"
              v-model="properties.prefix"
            ></v-textarea>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Suffix</label>
              <v-spacer></v-spacer>
              <v-icon
                class="mdi-18px border-gray p-1 cursor-pointer dark--text"
                v-on:click="suffix_autocomplete = !suffix_autocomplete"
                >mdi-text-box-plus-outline</v-icon
              >
            </div>
            <div class="mb-3" v-if="suffix_autocomplete">
              <v-autocomplete
                :items="toolsList"
                depressed
                hide-details
                outlined
                dense
                filled
                solo
                flat
                v-model="properties.suffix_id"
              ></v-autocomplete>
            </div>
            <v-textarea
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              rows="4"
              v-model="properties.suffix"
            ></v-textarea>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "UniqueId",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      prefix_autocomplete: false,
      suffix_autocomplete: false,
      properties: {
        startFrom: "Unique Id",
        dialog: false,
        prefix: null,
        suffix: null,
      },
      toolsList: ["Name", "Number", "Phone", "Email", "Date"],
    };
  },
};
</script>
