<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <v-text-field
        disabled
        depressed
        hide-details
        outlined
        dense
        filled
        solo
        flat
        v-model="properties.value"
      ></v-text-field>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <NumberComponent
      :drawer="dialog"
      v-on:close="dialog = false"
    ></NumberComponent>
  </div>
</template>
<script>
import NumberComponent from "@/view/pages/formBuilder/components/Number";
export default {
  name: "NumberInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Number",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    NumberComponent,
  },
};
</script>
