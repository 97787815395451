<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="font-level-3-bold border-bottom-gray pb-1">
        {{ properties.label }}
      </div>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <Section :drawer="dialog" v-on:close="dialog = false"></Section>
  </div>
</template>
<script>
import Section from "@/view/pages/formBuilder/components/Section";
export default {
  name: "SectionInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "My Title",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    Section,
  },
};
</script>
