<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 px-5 d-flex align-center border-bottom drawer-header"
        >
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Properties </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            tile
            class="light darken-4 me-2"
            v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="red--text fw-500">Field lavel</label>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    class="text--blue fw-500 cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                    >Rich Text</label
                  >
                </template>
                <v-card tile>
                  <v-card-title class="text-h5 cyan white--text d-flex">
                    <span>Edit Field label</span>
                    <v-spacer></v-spacer>
                    <v-icon
                      class="white--text cursor-pointer"
                      @click="dialog = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>
                  <div class="dialog-body p-5">
                    <editor
                      :editor="editor"
                      v-model="editorData"
                      :config="editorConfig"
                    ></editor>
                  </div>
                  <v-card-actions class="border-top">
                    <v-spacer></v-spacer>
                    <v-btn class="text" tile @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="cyan"
                      class="white--text"
                      depressed
                      @click="dialog = false"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.label"
            ></v-text-field>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Instructions</label>
            </div>
            <v-textarea
              v-model="properties.instructions"
              auto-grow
              dense
              filled
              solo
              flat
              color="cyan"
              row-height="20"
            ></v-textarea>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Initial State</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.initial_state"
              label="Checked"
              class="mt-0"
            ></v-checkbox>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">State Display Message</label>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="mdi-18px ml-2"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span style="font-size: 13px"
                  >This message display as per the checked / unchecked<br />
                  status.It will also be used in email notification</span
                >
              </v-tooltip>
            </div>
            <div class="two-column d-flex">
              <div class="w-50 pr-3">
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="properties.max_character"
                ></v-text-field>
                <div class="d-flex pt-1">
                  <label class="text-muted">When Checked</label>
                </div>
              </div>
              <div class="w-50 pl-3">
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="properties.min_character"
                ></v-text-field>
                <div class="d-flex pt-1">
                  <label class="text-muted">When Unchecked</label>
                </div>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Validation</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.mandatory"
              label="Mandatory"
              class="mt-1"
            ></v-checkbox>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Visibility</label>
            </div>
            <v-radio-group
              v-model="properties.visibility"
              hide-details
              class="mt-0"
            >
              <v-radio label="Hide Field" value="hide"></v-radio>
              <v-radio label="Disable Field" value="disable"></v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "Decision_box",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      editor: TinyMCE,
      editorData: "<p>Decision Box</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      properties: {
        label: "Decision Box",
        dialog: false,
        hidelabel: false,
        instructions: null,
        placeholder: null,
        fieldSize: "small",
        initial_state: false,
        min_character: null,
        max_character: null,
        visibility: null,
        email_confirmation: false,
      },
      domains: [
        "Allow All Domains",
        "Allow Specific Domains",
        "Restrict Specific Domains",
      ],
    };
  },
  components: {
    editor: TinyMCE,
  },
};
</script>
