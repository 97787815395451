<template>
  <div class="tabs-form main-create-page">
    <v-card>
      <v-card-text>
        <div class="d-flex align-center pb-2">
          <h3 class="font-weight-700 font-size-24 color-custom-blue">Create</h3>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            class="custom-grey-border custom-bold-button"
            v-on:click="goBack"
          >
            <v-icon small left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
          <v-btn
            class="custom-bold-button ms-2"
            depressed
            :disabled="pageLoading"
            color="cyan white--text"
            v-on:click="saveForm()"
            >Save</v-btn
          >
        </div>
        <v-tabs
          v-model="tab"
          color="cyan"
          fixed-tabs
          grow
          class="custom-tab-transparent mb-2"
          hide-slider
        >
          <v-tab :value="tab1" class="font-size-15 font-weight-600"
            >Add Form</v-tab
          >
          <v-tab :value="tab2" class="font-size-15 font-weight-600"
            >Form Builder</v-tab
          >
        </v-tabs>

        <!-- <v-divider class="my-0"></v-divider> -->
        <v-tabs-items v-model="tab">
          <v-tab-item :value="tab1"
            ><v-card>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col md="2" class="my-auto">
                      <label for="" class="v-label">Title</label>
                    </v-col>
                    <v-col md="6">
                      <TextField
                        dense
                        filled
                        solo
                        flat
                        placeholder="Enter Title"
                        hide-details
                        color="cyan"
                        :loading="pageLoading"
                        :disabled="pageLoading"
                        v-model="form.title"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" class="my-auto">
                      <label for="" class="v-label">Job Types</label>
                    </v-col>
                    <v-col md="6">
                      <v-select
                        dense
                        filled
                        solo
                        flat
                        placeholder="Select Job Types"
                        hide-details
                        color="cyan"
                        :loading="pageLoading"
                        :disabled="pageLoading"
                        :items="jobType"
                        v-model="form.job_type"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2" class="">
                      <label for="" class="v-label">Terms & Conditions</label>
                    </v-col>
                    <v-col md="6"
                      ><editor
                        :editor="editor"
                        v-model="form.editorData"
                        :config="editorConfig"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text> </v-card
          ></v-tab-item>
          <v-tab-item :value="tab2">
            <div class="d-flex" style="height: calc(100vh - 180px)">
              <div class="tools-wrapper">
                <h3
                  class="px-3 py-2 font-weight-700 font-size-24 custom-headline color-custom-blue border-bottom-gray"
                >
                  ALL Fields
                </h3>
                <div class="tools-wrapper-inner">
                  <Container
                    group-name="form-builder-grpup"
                    @drop="onDrop('field_list', $event)"
                    :get-child-payload="getChildPayload1"
                    behaviour="copy"
                    class="d-flex flex-wrap"
                  >
                    <Draggable v-for="data in field_list" :key="data.id">
                      <div class="btx-draggable">
                        <img
                          :src="$assetURL(data.icon)"
                          alt=""
                          width="18"
                          class="mr-1"
                        />
                        {{ data.name }}
                      </div>
                    </Draggable>
                  </Container>
                </div>
              </div>
              <div class="formBuilder-wrapper">
                <v-layout
                  class="px-3 border-bottom-gray d-flex align-center"
                  style="
                    padding-top: 6px;
                    padding-bottom: 7px;
                    position: sticky;
                    top: 0;
                    background: #eef0f8;
                    z-index: 6;
                  "
                >
                  <!-- <div class="font-size-15 fw-600">Preview</div> -->
                  <h3
                    class="font-weight-700 font-size-24 custom-headline color-custom-blue"
                  >
                    Preview
                  </h3>
                  <v-spacer></v-spacer>
                  <!-- <v-btn
                    depressed
                    class="custom-grey-border custom-bold-button"
                    v-on:click="goBack"
                  >
                    <v-icon small left>mdi-keyboard-backspace</v-icon>
                    Back
                  </v-btn>
                  <v-btn
                    class="custom-bold-button ms-2"
                    depressed
                    :disabled="pageLoading"
                    color="cyan white--text"
                    v-on:click="saveForm()"
                    >Save</v-btn
                  > -->
                </v-layout>
                <div id="formBuilderDiv" class="formBuilder-inner">
                  <div
                    class="selectedType border-bottom-gray"
                    v-on:click="form_name_drawer = true"
                  >
                    <div class="form-fields py-5">
                      <div class="form-grp px-5">
                        <div class="font-weight-600 font-size-15">
                          Sample Form
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <pre>{{ db_components }}</pre> -->
                  <div
                    class="builderFormWrapperView"
                    :class="db_components.length == 0 ? 'form-blank' : ''"
                  >
                    <Container
                      group-name="form-builder-grpup"
                      @drop="onDrop('db_components', $event)"
                      :get-child-payload="getChildPayload2"
                      :drop-placeholder="dropPlaceholderOptions"
                      class="form-drag-container builderFormWrapper"
                    >
                      <Draggable
                        v-for="(element, index) in db_components"
                        :key="index"
                        class="selectedType"
                      >
                        <component
                          :del-index="index"
                          :element-name="element.component_value"
                          v-bind:is="element.component"
                          v-on:delete="delete_element($event)"
                          v-on:duplicate="copy_element($event)"
                        ></component>
                      </Draggable>
                    </Container>
                  </div>
                </div>
              </div>
              <!-- form Name Drawer start-->
              <v-navigation-drawer
                v-model="form_name_drawer"
                fixed
                width="600"
                right
                temporary
                stateless
                style="z-index: 100"
              >
                <div class="drawer-wrapper">
                  <v-flex
                    class="py-3 px-5 d-flex align-center border-bottom drawer-header"
                  >
                    <!-- <div class="font-level-3-bold">Praperties</div> -->
                    <div
                      class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
                    >
                      <span style="font-size: 18px"> Properties </span>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      tile
                      class="light darken-4 me-2"
                      v-on:click="form_name_drawer = false"
                      >Cancel</v-btn
                    >
                    <v-btn tile depressed color="white--text" class="cyan"
                      >Save</v-btn
                    >
                  </v-flex>
                  <div class="drawer-content pt-5">
                    <div class="form-grp px-5 mb-4">
                      <div class="d-flex pb-1">
                        <label class="red--text fw-500">Form Title</label>
                      </div>
                      <v-text-field
                        depressed
                        hide-details
                        outlined
                        dense
                        filled
                        solo
                        flat
                        v-model="form_name_property.label"
                      ></v-text-field>
                    </div>
                    <div class="form-grp px-5 mb-4">
                      <div class="d-flex pb-1">
                        <label class="fw-500">Description</label>
                      </div>
                      <v-textarea
                        depressed
                        hide-details
                        outlined
                        dense
                        filled
                        solo
                        flat
                        row-height="20"
                        v-model="form_name_property.description"
                      ></v-textarea>
                    </div>
                  </div>
                </div>
              </v-navigation-drawer>
              <!-- form Name Drawer end-->
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SingleLineInput from "@/view/pages/formBuilder/InputComponents/SingleLineInput";
import NumberInput from "@/view/pages/formBuilder/InputComponents/NumberInput";
import PhoneInput from "@/view/pages/formBuilder/InputComponents/PhoneInput";
import NameInput from "@/view/pages/formBuilder/InputComponents/NameInput";
import DateInput from "@/view/pages/formBuilder/InputComponents/DateInput";
import TimeInput from "@/view/pages/formBuilder/InputComponents/TimeInput";
import WebsiteInput from "@/view/pages/formBuilder/InputComponents/WebsiteInput";
import SectionInput from "@/view/pages/formBuilder/InputComponents/SectionInput";
import MultiLineInput from "@/view/pages/formBuilder/InputComponents/MultiLineInput";
import AddressInput from "@/view/pages/formBuilder/InputComponents/AddressInput";
import EmailInput from "@/view/pages/formBuilder/InputComponents/EmailInput";
import DecisionBoxInput from "@/view/pages/formBuilder/InputComponents/DecisionBoxInput";
import CheckboxInput from "@/view/pages/formBuilder/InputComponents/CheckboxInput";
import ChecklistInput from "@/view/pages/formBuilder/InputComponents/ChecklistInput";
import RadioInput from "@/view/pages/formBuilder/InputComponents/RadioInput";
import CurrencyInput from "@/view/pages/formBuilder/InputComponents/CurrencyInput";
import RatingInput from "@/view/pages/formBuilder/InputComponents/RatingInput";
import ImageUploadInput from "@/view/pages/formBuilder/InputComponents/ImageUploadInput";
import SignatureInput from "@/view/pages/formBuilder/InputComponents/SignatureInput";
import PageBreakInput from "@/view/pages/formBuilder/InputComponents/PageBreakInput";
import UniqueIdInput from "@/view/pages/formBuilder/InputComponents/UniqueIdInput";
import TermsAndConditionInput from "@/view/pages/formBuilder/InputComponents/TermsAndConditionInput";
import DescriptionInput from "@/view/pages/formBuilder/InputComponents/DescriptionInput";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
// import { v4 as uuidv4 } from "uuid";

import { Container, Draggable } from "vue-smooth-dnd";

export default {
  name: "Form_builder",
  data() {
    return {
      tab: "tab1",
      jobType: [],
      editor: TinyMCE,

      editorConfig: {},
      form: {
        title: "",
        job_type: null,
        editorData: "<p>Terms Conditions</p>",
      },
      pageLoading: false,
      form_name_drawer: false,
      form_name_property: {
        description: null,
        label: null,
      },
      dialog: {
        single_line: false,
        number: false,
        name: false,
        phone: false,
        date: false,
        time: false,
        website: false,
        section: false,
        multi_line: false,
        address: false,
        email: false,
        decision_box: false,
        checkbox: false,
        radio: false,
        currency: false,
        image_upload: false,
        rating: false,
        signature: false,
        terms_conditions: false,
        page_break: false,
        unique_id: false,
      },
      field_list: [
        {
          name: "Single Line",
          dialog: "single_line",
          component: "SingleLineInput",
          icon: "media/form-svg/single-line.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Number",
          dialog: "number",
          component: "NumberInput",
          icon: "media/form-svg/number.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Name",
          dialog: "name",
          component: "NameInput",
          icon: "media/form-svg/name.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Phone",
          dialog: "phone",
          component: "PhoneInput",
          icon: "media/form-svg/phone.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Date",
          dialog: "date",
          component: "DateInput",
          icon: "media/form-svg/date.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Time",
          dialog: "time",
          component: "TimeInput",
          icon: "media/form-svg/time.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Website",
          dialog: "website",
          component: "WebsiteInput",
          icon: "media/form-svg/website.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Section",
          dialog: "section",
          component: "SectionInput",
          icon: "media/form-svg/section.svg",
          class_name: "btx-draggable",
        },
        // {
        //   name: "Multiline",
        //   dialog: "multi_line",
        //   component: "MultiLineInput",
        //   icon: "media/form-svg/multiline.svg",
        //   class_name: "btx-draggable",
        // },
        // {
        //   name: "Address",
        //   dialog: "address",
        //   component: "AddressInput",
        //   icon: "media/form-svg/address.svg",
        //   class_name: "btx-draggable",
        // },
        {
          name: "Description",
          dialog: "description",
          component: "DescriptionInput",
          icon: "media/form-svg/description.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Email",
          dialog: "email",
          component: "EmailInput",
          icon: "media/form-svg/email.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Decision Box",
          dialog: "decision_box",
          component: "DecisionBoxInput",
          icon: "media/form-svg/decision-box.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Radio",
          dialog: "radio",
          component: "RadioInput",
          icon: "media/form-svg/radio.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Checkbox",
          dialog: "checkbox",
          component: "CheckboxInput",
          icon: "media/form-svg/checkbox.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Checklist",
          dialog: "checklist",
          component: "ChecklistInput",
          icon: "media/form-svg/checkbox.svg",
          class_name: "btx-draggable",
        },
        // {
        //   name: "Currency",
        //   dialog: "currency",
        //   component: "CurrencyInput",
        //   icon: "media/form-svg/currency.svg",
        //   class_name: "btx-draggable",
        // },
        {
          name: "Image Upload",
          dialog: "image_upload",
          component: "ImageUploadInput",
          icon: "media/form-svg/image-upload.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Page Break",
          dialog: "page_break",
          component: "PageBreakInput",
          icon: "media/form-svg/page-break.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Rating",
          dialog: "rating",
          component: "RatingInput",
          icon: "media/form-svg/rating.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Unique Id",
          dialog: "unique_id",
          component: "UniqueIdInput",
          icon: "media/form-svg/unique-id.svg",
          class_name: "btx-draggable",
        },
        {
          name: "Signature",
          dialog: "signature",
          component: "SignatureInput",
          icon: "media/form-svg/signature.svg",
          class_name: "btx-draggable",
        },
        // {
        //   name: "Terms and Conditions",
        //   dialog: "terms_conditions",
        //   component: "TermsAndConditionInput",
        //   icon: "media/form-svg/terms-conditions.svg",
        //   class_name: "btx-draggable",
        // },
      ],
      db_components: [
        // {
        // 	component: SingleLineInput,
        // 	component_value: "single_line",
        // 	id: uuidv4(),
        // },
      ],
      drag_component: null,
      element_name: null,
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "50",
        showOnTop: true,
      },
    };
  },
  methods: {
    copy_element(index) {
      this.db_components.splice(index + 1, 0, this.db_components[index]);
    },
    delete_element(index) {
      this.db_components.splice(index, 1);
    },
    getComponent(component) {
      let result = null;
      switch (component) {
        case "single_line":
          result = "SingleLineInput";
          break;
        case "number":
          result = NumberInput;
          break;
        case "name":
          result = NameInput;
          break;
        case "phone":
          result = PhoneInput;
          break;
        case "date":
          result = DateInput;
          break;
        case "time":
          result = TimeInput;
          break;
        case "website":
          result = WebsiteInput;
          break;
        case "multiline":
          result = MultiLineInput;
          break;
        case "section":
          result = SectionInput;
          break;
        case "address":
          result = AddressInput;
          break;
        case "email":
          result = EmailInput;
          break;
        case "decision_box":
          result = DecisionBoxInput;
          break;
        case "radio":
          result = RadioInput;
          break;
        case "checkbox":
          result = CheckboxInput;
          break;
        case "checklist":
          result = ChecklistInput;
          break;
        case "currency":
          result = CurrencyInput;
          break;
        case "image_upload":
          result = ImageUploadInput;
          break;
        case "rating":
          result = RatingInput;
          break;
        case "signature":
          result = SignatureInput;
          break;
        case "page_break":
          result = PageBreakInput;
          break;
        case "unique_id":
          result = UniqueIdInput;
          break;
        case "terms_conditions":
          result = TermsAndConditionInput;
          break;
        case "description":
          result = DescriptionInput;
          break;
      }
      return result;
    },
    open_dialog(type) {
      this.dialog[type] = true;
    },

    //vue smooth dnd
    onDrop(collection, dropResult) {
      this[collection] = this.applyDrag(this[collection], dropResult);
    },
    // onDrop(targetArray, event) {
    //   if (targetArray === "db_components") {
    //     this.db_components.push({
    //       component: event.payload.component,
    //       component_value: event.payload.dialog,
    //     });
    //   }
    // },
    getChildPayload1(index) {
      return this.field_list[index];
    },
    getChildPayload2(index) {
      return this.db_components[index];
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
  },
  components: {
    SingleLineInput,
    NumberInput,
    PhoneInput,
    NameInput,
    DateInput,
    TimeInput,
    WebsiteInput,
    SectionInput,
    MultiLineInput,
    AddressInput,
    EmailInput,
    DecisionBoxInput,
    CheckboxInput,
    ChecklistInput,
    RadioInput,
    CurrencyInput,
    RatingInput,
    SignatureInput,
    ImageUploadInput,
    UniqueIdInput,
    PageBreakInput,
    TermsAndConditionInput,
    DescriptionInput,
    Container,
    Draggable,
    editor: TinyMCE,
  },
};
</script>
<style scoped>
/* tabs css  */

.custom-tab-transparent.v-tabs--fixed-tabs > .v-tabs-bar .v-tab.v-tab--active {
  background-color: #00bcd4 !important;
  color: #fff !important;
  overflow: hidden;
}
.custom-tab-transparent.v-tabs--fixed-tabs
  > .v-tabs-bar
  .v-tab.v-tab--active
  .grey--text.text--darken-4 {
  color: #fff !important;
  caret-color: #fff !important;
}

.custom-tab-transparent.v-tabs .v-tab {
  background: #00bcd429;
  width: 25%;
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}

/* .custom-tab-transparent.v-tabs .v-tab:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 0px) 50%
  );
} */

/* .custom-tab-transparent.v-tabs .v-tab.v-tab:last-child {
  clip-path: polygon(
    0% 0%,
    calc(100% + -0px) 0%,
    100% 100%,
    calc(100% - 40px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
} */
</style>
