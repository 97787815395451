<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <div class="two-column d-flex">
        <div class="w-50 pr-3">
          <v-text-field
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.first_name_label"
          ></v-text-field>
          <div class="d-flex pt-1">
            <label class="text-muted">{{ properties.first_name_label }}</label>
          </div>
        </div>
        <div class="w-50 pl-3">
          <v-text-field
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.last_name_label"
          ></v-text-field>
          <div class="d-flex pt-1">
            <label class="text-muted">{{ properties.last_name_label }}</label>
          </div>
        </div>
      </div>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <Name :drawer="dialog" v-on:close="dialog = false"></Name>
  </div>
</template>
<script>
import Name from "@/view/pages/formBuilder/components/Name";
export default {
  name: "NameInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Name",
        first_name_label: "First",
        last_name_label: "Last",
        first_name_value: null,
        last_name_value: null,
        intructions: "lorem",
      },
    };
  },
  components: {
    Name,
  },
};
</script>
