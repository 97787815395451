<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="">{{ properties.label }}</label>
      </div>
      <div class="w-50">
        <v-text-field
          disabled
          depressed
          hide-details
          outlined
          dense
          filled
          solo
          flat
          v-model="properties.value"
        ></v-text-field>
      </div>
    </div>
    <UniqueId :drawer="dialog" v-on:close="dialog = false"></UniqueId>
  </div>
</template>
<script>
import UniqueId from "@/view/pages/formBuilder/components/UniqueId";
export default {
  name: "UniqueIdInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Unique Id",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    UniqueId,
  },
};
</script>
