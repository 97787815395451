<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Properties </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="red--text fw-500">Field lavel</label>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    class="text--blue fw-500 cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                    >Rich Text</label
                  >
                </template>
                <v-card tile>
                  <v-card-title class="text-h5 cyan white--text d-flex">
                    <span>Edit Field label</span>
                    <v-spacer></v-spacer>
                    <v-icon
                      class="white--text cursor-pointer"
                      @click="dialog = false"
                      >mdi-close</v-icon
                    >
                  </v-card-title>
                  <div class="dialog-body p-5">
                    <editor
                      :editor="editor"
                      v-model="editorData"
                      :config="editorConfig"
                    ></editor>
                  </div>
                  <v-card-actions class="border-top">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" tile @click="dialog = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="cyan"
                      class="white--text"
                      depressed
                      @click="dialog = false"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-text-field
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.label"
            ></v-text-field>
            <v-checkbox
              hide-details
              v-model="properties.hidelabel"
              label="Hide Field Label"
              class="mt-1"
            ></v-checkbox>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Instructions</label>
            </div>
            <v-textarea
              v-model="properties.instructions"
              auto-grow
              dense
              filled
              solo
              flat
              color="cyan"
              row-height="20"
            ></v-textarea>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Field Size</label>
            </div>
            <v-radio-group
              v-model="properties.fieldSize"
              hide-details
              row
              class="mt-0 radio-style"
            >
              <v-radio label="Small" value="small"></v-radio>
              <v-radio label="Medium" value="medium"></v-radio>
              <v-radio label="Large" value="large"></v-radio>
            </v-radio-group>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-3">
              <label class="fw-500">Name of Elements</label>
              <v-spacer></v-spacer>
              <label class="fw-500">Mondatory</label>
            </div>
            <ul>
              <li class="d-flex mb-5">
                <v-icon class="drag-icon">mdi-drag</v-icon>
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  placeholder="Title"
                  v-model="properties.title"
                ></v-text-field>
                <v-checkbox
                  hide-details
                  v-model="properties.titleCheck"
                  class="mx-5 mt-0"
                ></v-checkbox>
              </li>
              <li class="d-flex mb-5">
                <v-icon class="drag-icon">mdi-drag</v-icon>
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  placeholder="First Name"
                  v-model="properties.first_name"
                ></v-text-field>
                <v-checkbox
                  hide-details
                  v-model="properties.first_name_check"
                  class="mx-5 mt-0"
                ></v-checkbox>
              </li>
              <li class="d-flex mb-5">
                <v-icon class="drag-icon">mdi-drag</v-icon>
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  placeholder="Last Name"
                  v-model="properties.last_name"
                ></v-text-field>
                <v-checkbox
                  hide-details
                  v-model="properties.last_name_check"
                  class="mx-5 mt-0"
                ></v-checkbox>
              </li>
              <li class="d-flex mb-5">
                <v-icon class="drag-icon">mdi-drag</v-icon>
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  placeholder="Middle Name"
                  v-model="properties.middle_name"
                ></v-text-field>
                <v-checkbox
                  hide-details
                  v-model="properties.middle_name_check"
                  class="mx-5 mt-0"
                ></v-checkbox>
              </li>
            </ul>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Input Type</label>
            </div>
            <v-autocomplete
              :items="inputTypeValue"
              depressed
              hide-details
              outlined
              dense
              filled
              solo
              flat
              v-model="properties.inputType"
            ></v-autocomplete>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Placeholder Text</label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.configPlaceholder"
              class="mt-0"
              label="Configure Placeholder"
            ></v-checkbox>
            <div class="mt-3" v-if="properties.configPlaceholder">
              <v-text-field
                depressed
                hide-details
                outlined
                dense
                filled
                solo
                flat
                placeholder="First Name"
                v-model="properties.first_name_placeholder"
                class="mb-3"
              ></v-text-field>
              <v-text-field
                depressed
                hide-details
                outlined
                dense
                filled
                solo
                flat
                placeholder="Middle Name"
                v-model="properties.middle_name_placeholder"
                class="mb-3"
              ></v-text-field>
              <v-text-field
                depressed
                hide-details
                outlined
                dense
                filled
                solo
                flat
                placeholder="Last Name"
                v-model="properties.last_name_placeholder"
                class="mb-3"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Visibility</label>
            </div>
            <v-radio-group
              v-model="properties.visibility"
              hide-details
              class="mt-0"
            >
              <v-radio label="Hide Field" value="hide"></v-radio>
              <v-radio label="Disable Field" value="disable"></v-radio>
            </v-radio-group>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500"
                >Privacy
                <v-icon class="mdi-18px">mdi-information-outline</v-icon>
              </label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.personal"
              label="Mark as Personal"
              class="mt-1"
            ></v-checkbox>
            <v-checkbox
              hide-details
              v-model="properties.encrypt"
              label="Encrypt"
              class="mt-1"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "Name",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      editor: TinyMCE,
      editorData: "<p>Name</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      properties: {
        label: "Name",
        dialog: false,
        hidelabel: false,
        instructions: null,
        placeholder: null,
        fieldSize: "small",
        inputType: "Any Character",
        visibility: null,
        configPlaceholder: false,
      },
      inputTypeValue: ["Any Character", "letter Only", "Letter and Numbers"],
    };
  },
  components: {
    editor: TinyMCE,
  },
};
</script>
<style scoped></style>
