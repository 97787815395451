<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="border-gray px-3 py-6">Add content...</div>
    </div>
    <Description :drawer="dialog" v-on:close="dialog = false"></Description>
  </div>
</template>
<script>
import Description from "@/view/pages/formBuilder/components/Description";
export default {
  name: "SignatureInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        value: null,
      },
    };
  },
  components: {
    Description,
  },
};
</script>
