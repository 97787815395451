<template>
  <div class="page-break-wrapper">
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 px-5 d-flex align-center border-bottom drawer-header"
        >
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Properties </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn depressed tile class="me-2" v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-2">
            <div class="d-flex pb-1">
              <label class="fw-500">Page Title</label>
              <v-spacer></v-spacer>
              <label
                class="text--blue fw-500 cursor-pointer"
                v-on:click="reArrangeDrawer = true"
                >Rearrange</label
              >
            </div>
          </div>
          <div class="form-grp px-5 mb-4">
            <ul>
              <li
                class="d-flex mb-5"
                v-for="(item, index) in Pages"
                :key="index"
              >
                <div class="page_count">{{ item.page_no }}</div>
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="item.page_name"
                ></v-text-field>
              </li>
            </ul>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Show page title in</label>
            </div>
            <v-radio-group
              v-model="properties.pagetitle"
              row
              hide-details
              class="mt-0"
            >
              <v-radio label="Navigation Bar" value="navigationbar"></v-radio>
              <v-radio label="Page" value="page"></v-radio>
              <v-radio label="None" value="none"></v-radio>
            </v-radio-group>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Navigation options</label>
            </div>
            <v-radio-group
              v-model="properties.navigationOption"
              row
              hide-details
              class="mt-0"
            >
              <v-radio label="Step" value="step"></v-radio>
              <v-radio label="Bar" value="bar"></v-radio>
              <v-radio label="None" value="none"></v-radio>
            </v-radio-group>
          </div>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500">Field Validation</label>
            </div>
            <v-radio-group
              v-model="properties.pagevalidation"
              row
              hide-details
              class="mt-0"
            >
              <v-radio
                label="Before proceeding to the next page"
                value="proceeding_next_page"
              ></v-radio>
              <v-radio
                label="On final submission"
                value="finalsubmission"
              ></v-radio>
            </v-radio-group>
          </div>
          <v-divider></v-divider>
          <div class="form-grp px-5 mb-4">
            <div class="d-flex pb-1">
              <label class="fw-500"> Other options </label>
            </div>
            <v-checkbox
              hide-details
              v-model="properties.page_no"
              label="Display page number"
              class="mt-1"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <!-- Rearange page Drawer -->
    <v-navigation-drawer
      v-model="reArrangeDrawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 101"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 px-5 d-flex align-center border-bottom drawer-header"
        >
          <!-- <div class="font-level-3-bold">Rearrange Pages</div> -->
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Rearrange Pages </span>
          </div>
          <v-spacer></v-spacer>
          <v-icon v-on:click="reArrangeDrawer = false">mdi-close</v-icon>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <ul>
              <li
                class="d-flex mb-5"
                v-for="(item, index) in Pages"
                :key="index"
              >
                <div class="page_count">
                  {{ item.page_no }}
                </div>
                <v-text-field
                  depressed
                  hide-details
                  outlined
                  dense
                  filled
                  solo
                  flat
                  v-model="item.page_name"
                ></v-text-field>
                <div class="page_drag">
                  <v-icon>mdi-drag</v-icon>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Radio",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reArrangeDrawer: false,
      properties: {
        label: "Page Break",
        page_no: false,
        pagevalidation: null,
        navigationOption: null,
        pagetitle: null,
      },
      Pages: [
        { page_name: "page 1", page_no: 1 },
        { page_name: "page 2", page_no: 2 },
        { page_name: "page 3", page_no: 3 },
        { page_name: "page 4", page_no: 4 },
        { page_name: "page 5", page_no: 5 },
      ],
    };
  },
};
</script>
<style scoped></style>
