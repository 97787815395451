<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate">mdi-content-duplicate</v-icon>
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="">Page Break</div>
    </div>
    <PageBreak :drawer="dialog" v-on:close="dialog = false"></PageBreak>
  </div>
</template>
<script>
import PageBreak from "@/view/pages/formBuilder/components/PageBreak";
export default {
  name: "PageBreakInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "PageBreak",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    PageBreak,
  },
};
</script>
