<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <UploadFile></UploadFile>
    </div>
    <ImageUpload :drawer="dialog" v-on:close="dialog = false"></ImageUpload>
  </div>
</template>
<script>
import UploadFile from "@/view/components/UploadFile";
import ImageUpload from "@/view/pages/formBuilder/components/ImageUpload";
export default {
  name: "ImageUploadInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Image Upload",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    UploadFile,
    ImageUpload,
  },
};
</script>
