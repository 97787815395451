<template>
  <v-row class="border-light-grey mx-0 py-0" style="position: relative">
    <v-progress-linear
      v-if="loading"
      color="orange darken-2"
      indeterminate
      height="4"
      class="file-upload-loader"
    ></v-progress-linear>
    <v-col
      md="3"
      v-for="(row, index) in files"
      class="text-center h-100px py-3 border-right-light-grey"
      :key="index"
      :class="{ 'border-top-light-grey': index > 3 }"
    >
      <v-hover v-slot="{ hover }">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <span class="mime-icon" v-bind="attrs" v-on="on">
              <inline-svg :src="`/media/mime/${getMime(row)}.svg`" />
            </span>
            <v-btn
              class="file-upload-delete"
              icon
              depressed
              small
              color="red lighten-1"
              v-on:click="removeFile(index)"
              ><v-icon small>mdi-delete</v-icon></v-btn
            >
            <v-btn
              class="file-upload-download"
              v-on:click="downloadFile(row)"
              icon
              depressed
              small
              color="blue darken-4"
              ><v-icon small>mdi-download</v-icon></v-btn
            >
          </template>
          <span>{{ row.name }}</span>
        </v-tooltip>
      </v-hover>
    </v-col>
    <v-col
      md="12"
      class="py-3"
      :class="{ 'border-top-light-grey': files.length }"
    >
      <div
        v-on:click="selectFile()"
        class="cursor-pointer border text-center px-3 py-5 dashed-border-semi-bold-grey"
      >
        <v-icon>mdi-progress-upload</v-icon><br />
        Choose File
      </div>
      <v-file-input
        class="d-none"
        ref="uploadFile"
        v-on:change="handleFileSelection"
      ></v-file-input>
    </v-col>
  </v-row>
</template>

<script>
import { split, last } from "lodash";

export default {
  data() {
    return {
      loading: false,
      files: [],
    };
  },
  methods: {
    downloadFile(file) {
      if (file?.url) {
        window.open(file.url, "_blank");
      }
    },
    getMime(file) {
      if (!file?.name) return "unknown";
      return last(split(file.name, ".")) || "unknown";
    },
    selectFile() {
      this.$refs.uploadFile.$el.querySelector("input").click();
    },
    handleFileSelection(event) {
      const file = event.target.files[0];
      if (file) {
        this.files.push({ name: file.name, url: URL.createObjectURL(file) });
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
