<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <div class="mb-3">
        <v-text-field
          disabled
          depressed
          hide-details
          outlined
          dense
          filled
          solo
          flat
          v-model="properties.last_name_label"
        ></v-text-field>
        <div class="d-flex pt-1">
          <label class="text-muted">{{ properties.street_address }}</label>
        </div>
      </div>
      <div class="mb-3">
        <v-text-field
          disabled
          depressed
          hide-details
          outlined
          dense
          filled
          solo
          flat
          v-model="properties.value"
        ></v-text-field>
        <div class="d-flex pt-1">
          <label class="text-muted">{{ properties.address_line2 }}</label>
        </div>
      </div>
      <div class="mb-3 d-flex">
        <div class="w-50 pr-3">
          <v-text-field
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.value"
          ></v-text-field>
          <div class="d-flex pt-1">
            <label class="text-muted">{{ properties.city }}</label>
          </div>
        </div>
        <div class="w-50 pl-3">
          <v-text-field
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.value"
          ></v-text-field>
          <div class="d-flex pt-1">
            <label class="text-muted">{{ properties.state }}</label>
          </div>
        </div>
      </div>
      <div class="mb-3 d-flex">
        <div class="w-50 pr-3">
          <v-text-field
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.value"
          ></v-text-field>
          <div class="d-flex pt-1">
            <label class="text-muted">{{ properties.postal_code }}</label>
          </div>
        </div>
        <div class="w-50 pl-3">
          <v-autocomplete
            items:countryList
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.value"
          ></v-autocomplete>
          <div class="d-flex pt-1">
            <label class="text-muted">{{ properties.country }}</label>
          </div>
        </div>
      </div>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <Address :drawer="dialog" v-on:close="dialog = false"></Address>
  </div>
</template>
<script>
import Address from "@/view/pages/formBuilder/components/Address";
export default {
  name: "AddressInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Address",
        value: null,
        street_address: "Street Address",
        address_line2: "Address Line 2",
        city: "City",
        state: "State",
        postal_code: "Postal Code",
        country: "Country",
        intructions: "Intructions",
      },
      countryList: ["Country 1", "Country 2", "Country 3"],
    };
  },
  components: {
    Address,
  },
};
</script>
