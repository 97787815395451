<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <v-checkbox
        hide-details
        v-model="properties.value"
        label="Checkbox 1"
        class="mt-0"
      ></v-checkbox>
      <v-checkbox
        hide-details
        v-model="properties.value"
        label="Checkbox 2"
        class="mt-0"
      ></v-checkbox>
      <v-checkbox
        hide-details
        v-model="properties.value"
        label="Checkbox 3"
        class="mt-0"
      ></v-checkbox>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <CheckBox :drawer="dialog" v-on:close="dialog = false"></CheckBox>
  </div>
</template>
<script>
import CheckBox from "@/view/pages/formBuilder/components/CheckBox";
export default {
  name: "CheckboxInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Checkbox",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    CheckBox,
  },
};
</script>
