<template>
  <div class="form-fields py-5">
    <div class="form-grp px-5">
      <div class="actions">
        <v-icon class="setting" v-on:click="dialog = true">mdi-cog</v-icon>
        <v-icon class="duplicate" v-on:click="$emit('duplicate', delIndex)"
          >mdi-content-duplicate</v-icon
        >
        <v-icon class="delete" v-on:click="$emit('delete', delIndex)"
          >mdi-delete-outline</v-icon
        >
      </div>
      <div class="d-flex pb-1">
        <label class="fw-500">{{ properties.label }}</label>
      </div>
      <div class="d-flex">
        <div class="w-50">
          <v-text-field
            disabled
            depressed
            hide-details
            outlined
            dense
            filled
            solo
            flat
            v-model="properties.value"
          ></v-text-field>
        </div>
        <div
          class="border-gray d-flex justify-content-center align-items-center"
        >
          <strong class="w-500 p-1">USD</strong>
        </div>
      </div>
      <p class="text-muted mt-2 mb-0" v-if="properties.intructions != null">
        {{ properties.intructions }}
      </p>
    </div>
    <Currency :drawer="dialog" v-on:close="dialog = false"></Currency>
  </div>
</template>
<script>
import Currency from "@/view/pages/formBuilder/components/Currency";
export default {
  name: "CurrencyInput",
  props: {
    delIndex: {
      type: Number,
      default: 0,
    },
    elementName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      properties: {
        label: "Currency",
        value: null,
        intructions: "Intructions",
      },
    };
  },
  components: {
    Currency,
  },
};
</script>
