<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 100"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-3 align-center px-5 d-flex border-bottom drawer-header"
        >
          <div
            class="dialog-custom-header text-uppercase custom-header-blue-text white-background"
          >
            <span style="font-size: 18px"> Properties </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn depressed tile class="me-2" v-on:click="$emit('close', true)"
            >Cancel</v-btn
          >
          <v-btn tile depressed color="white--text" class="cyan">Save</v-btn>
        </v-flex>
        <div class="drawer-content pt-5">
          <div class="form-grp px-5 mb-4">
            <editor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"
            ></editor>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
export default {
  name: "Description",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      editor: TinyMCE,
      editorData: "<p>Description</p>",
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  components: {
    editor: TinyMCE,
  },
};
</script>
<style scoped></style>
